import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner9@2x.jpg'),
    heading: "Do more with your Value Score Insights and grow faster",
    subheading: "Offer differentiated service and offers to your customers based on their percentile groups, use Value Score filters in your Plan Actions and see how your customers scores change over time with advanced reporting and analysis to help you make the most of every customer."
  }

  return(
    <Layout>
      <SEO 
        title="Advanced Value Score | Billsby Pro | Take your subscription business to the next level" 
        description="Advanced Value Score and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/advanced-value-score"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;